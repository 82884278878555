<template>
  <div id="getRoc">
    <goBack></goBack>
    <broadPage>
      <div slot="content">
        <div class="content">
          <p class="get-title">Get ROC</p>
          <!-- from -->
          <div class="get-content">
            <p>From</p>
            <div class="border-contnet">
              <el-row>
                <el-col :span="10">
                  <div class="border-rignt">
                    <div class="item-left">
                      <img src="./images/balances.svg" />
                      <span>roUSD</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="14">
                  <div class="item-right">
                    <div>
                      <span>Balance: </span>
                      <span>{{rousdMount}}</span>
                    </div>
                    <el-input
                      v-model="coinFrom"
                      placeholder="0.00"
                      class="noBorder"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- to -->
          <div class="get-content">
            <p>To</p>
            <div class="border-contnet">
              <el-row>
                <el-col :span="10">
                  <div class="border-rignt">
                    <div class="item-left">
                      <img src="./images/balances.svg" />
                      <span>ROC</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="14">
                  <div class="item-right">
                    <div>
                      <span>Balance: </span>
                      <span>{{rocMount}}</span>
                    </div>
                    <el-input
                      v-model="coinTo"
                      placeholder="0.00"
                      class="noBorder"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <router-link to="/getRoc" class="btn btn-middle btn-primary mt-2 mb-3"
            >Trade</router-link
          >
          <div class="price">
            <el-row type="flex" justify="space-between">
              <el-col>
                <p>Price</p>
              </el-col>
              <el-col class="text-right">
                <span>1</span>
                <span> ROC</span>
                <span> = </span>
                <span>1.02</span>
                <span> roUSD</span>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" class="mt-2 mb-2">
              <el-col>
                <p>Slippage Tolerance
                  <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top">
                      <i class="fa fa-question-circle"></i>
                    </el-tooltip>
                </p>
              </el-col>
              <el-col class="text-right">
                  <el-input-number
                  size="mini"
                  @change="handleChange"
                  :min="1"
                  :max="100"
                  v-model="num"
                ></el-input-number>
                <div class="number-unit">%</div>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" class="mt-2 mb-2">
              <el-col>
                <p>Transaction Deadline
                  <el-tooltip class="item" effect="dark" content="Your transaction will revert if is pending for more than this long." placement="top">
                      <i class="fa fa-question-circle"></i>
                    </el-tooltip>
                </p>
              </el-col>
              <el-col class="text-right">
                <el-input-number
                  size="mini"
                  @change="handleChange2"
                  :min="1"
                  v-model="num2"
                ></el-input-number>
                <div class="number-unit">min</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </broadPage>
  </div>
</template>

<script>
import broadPage from "../../components/broadPage.vue";
import goBack from '../../components/goBack.vue';
import { rousdBlance, rocBlance, RousdToRoc, BigNum} from "../../unitls"
import _ from "lodash";
export default {
  name: "getRoc",
  data() {
    return {
      num: 1,
      num2: 20,
      coinFrom: "",
      coinTo: "",
      path: "/balances",
      address: localStorage.getItem('account'),
      rousdMount: 0,
      rocMount: 0,
    };
  },
  components: { broadPage, goBack },
  mounted () {
    this.init()
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    handleChange2(value) {
      console.log(value);
    },

    // 获取对应的rousd和roc
    init() {
      if (this.address) {
        // 获取rousdc的资产
        rousdBlance(this.address).then(val => {
          this.rousdMount = val
        })
        // 获取roc的资产
        rocBlance(this.address).then(val => {
          this.rocMount = val
        })
      }
    },

    // 根据rousd计算roc
    getRoc (val) {
      RousdToRoc(BigNum(val)).then(rocObj => {
        console.log(rocObj, 'rocObj')
        this.coinTo = rocObj[0]
      })
    }
  },
  watch: {
    // coinFrom: function (newVal, oldVal) {
    //   console.log(newVal, oldVal)
    //   if (newVal > 0) {
    //     _.debounce(this.getRoc(newVal), 250, { 'maxWait': 1000 });
    //   }
      
    // }
  }
};
</script>
<style lang="less" scoped>
#getRoc {
  .noBorder /deep/ .el-input__inner {
    border: 0 !important;
    height: 32px;
    line-height: 32px;
    padding: 0 15px 0 0px;
    padding-right: 0;
    font-size: 20px;
    color: #828282 !important;
    background: #f6f8fb;
    &:focus {
      box-shadow: none !important;
    }
  }
  .content {
    padding: 30px;
    .get-title {
      font-family: ParmaPetit;
      font-size: 24px;
      color: #085da3;
      margin-bottom: 30px;
    }
    .get-content {
      p {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #4f4f4f;
        margin-bottom: 15px;
      }
      .border-contnet {
        border: 1px solid #e0e0e0;
        margin-bottom: 18px;
        .border-rignt {
          border-right: 1px solid #e0e0e0;
        }
        .item-left {
          vertical-align: middle;
          display: inline-block;
          padding: 25px 0px 25px 20px;
          img {
            margin-top: -6px;
          }
          span {
            font-family: Louis George Cafe;
            font-size: 20px;
            color: #085da3;
            margin-left: 10px;
          }
        }
        .item-right {
          background-color: #f6f8fb;
          padding: 12px 20px;
          height: 80px;
          span {
            font-family: Louis George Cafe;
            font-size: 14px;
            line-height: 140%;
            color: #828282;
          }
          p {
            font-family: Louis George Cafe;
            font-size: 20px;
            color: #828282;
          }
        }
      }
    }
    .btn {
      width: 100%;
    }
    .price {
      position: relative;
      p {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #828282;
      }
      span {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #4f4f4f;
      }
      .number-unit {
        font-size: 14px;
        color: #4f4f4f;
        position: absolute;
        right: 30px;
        top: 3px;
      }
    }
    /deep/.el-input-number--mini .el-input__inner{
    padding-right:56px;
    }
  }
}
</style>
